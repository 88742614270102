var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 商品基本信息 ")]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品名称：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "width450",
                attrs: {
                  size: "mini",
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "请输入商品名称",
                  readonly: _vm.disabled,
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
              _vm.goodsType != "2"
                ? _c(
                    "div",
                    { staticStyle: { color: "#666", "line-height": "26px" } },
                    [
                      _vm._v(
                        " 建议：商品名称+规格，例：梅饴馆黑糖老梅干（典藏系列）55g装*6盒 "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.goodsType == "2"
                ? _c(
                    "div",
                    { staticStyle: { color: "#666", "line-height": "26px" } },
                    [
                      _vm._v(" 例：余杭径山 抹香阁民宿 体验禅茶文化"),
                      _c("br"),
                      _vm._v("建议使用：地区+名宿名称+名宿亮点+房型 "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品卖点：", prop: "trait" } },
            [
              _c("el-input", {
                staticClass: "width450",
                attrs: {
                  type: "textarea",
                  size: "mini",
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "请输入商品卖点",
                  readonly: _vm.disabled,
                },
                model: {
                  value: _vm.ruleForm.trait,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "trait", $$v)
                  },
                  expression: "ruleForm.trait",
                },
              }),
              _vm.goodsType != "2"
                ? _c(
                    "div",
                    { staticStyle: { color: "#666", "line-height": "26px" } },
                    [_vm._v(" 例：纯天然无污染，爽脆可口 ")]
                  )
                : _vm._e(),
              _vm.goodsType == "2"
                ? _c(
                    "div",
                    { staticStyle: { color: "#666", "line-height": "26px" } },
                    [_vm._v(" 例：超大落地窗 江景 巨屏投影 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "商品封面图：" } },
            [
              _c("UpImage", {
                attrs: {
                  disabled: _vm.disabled,
                  "image-list": _vm.ruleForm.coverImageList,
                  num: 1,
                },
                on: {
                  "update:imageList": function ($event) {
                    return _vm.$set(_vm.ruleForm, "coverImageList", $event)
                  },
                  "update:image-list": function ($event) {
                    return _vm.$set(_vm.ruleForm, "coverImageList", $event)
                  },
                },
              }),
              _c("div", [
                _vm._v(
                  "营销位显示图片，建议突出商品卖点。建议：800px*800px，2M以内。"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "商品图片：" } },
            [
              _c("UpImage", {
                attrs: {
                  disabled: _vm.disabled,
                  "image-list": _vm.ruleForm.imageUrlList,
                  num: 10,
                },
                on: {
                  "update:imageList": function ($event) {
                    return _vm.$set(_vm.ruleForm, "imageUrlList", $event)
                  },
                  "update:image-list": function ($event) {
                    return _vm.$set(_vm.ruleForm, "imageUrlList", $event)
                  },
                },
              }),
              _c("div", [
                +_vm.goodsType === 2
                  ? _c("div", [
                      _vm._v(
                        " 建议：720px*480px，2M以内；图片可拖动排序，限10张。 "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        " 建议：800px*800px，2M以内；图片可拖动排序，限10张。 "
                      ),
                    ]),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频上传：" } },
            [
              _c("UploadVideoOss", {
                attrs: { disabled: _vm.disabled, url: _vm.ruleForm.videoUrl },
                on: { success: _vm.changeVideo },
              }),
              _c("div", [
                +_vm.goodsType === 2
                  ? _c("div", [
                      _vm._v(
                        " 建议：清晰度720p以上，画面比例3:2，3分钟以内，300M以内。 "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        " 建议：清晰度720p以上，画面比例1:1，3分钟以内，300M以内。 "
                      ),
                    ]),
              ]),
            ],
            1
          ),
          _vm.isNeedAppendShop()
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "关联门店：" } },
                [
                  _c("ChangeHomeShop", {
                    attrs: {
                      disabled: _vm.disabled,
                      "shop-list-dto": _vm.shopList,
                    },
                    on: { changeHomeShop: _vm.changeHomeShop },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }